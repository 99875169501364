<template>
  <div class="template-message">
    <div class="title"></div>
    <p class="light">{{bodytext}}</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    bodytext:String,
    titleBalise:String
  },
  methods:{
    getBaliseTitle(balise,msg){
      let tag = balise? balise : 'h1';
      let titleSection = document.querySelector('.title');
        titleSection.innerHTML = "<"+tag+">"+msg+"</"+tag+">";
    }
  },
  mounted() {
    this.getBaliseTitle(this.titleBalise,this.msg)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.home p{
  font-size: 0.8em;
}
</style>
