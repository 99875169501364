<template>
  <!--  <div id="nav">-->
  <!--    <router-link to="/">Accueil</router-link>-->
  <!--    <router-link to="/details">En savoir plus</router-link>-->
  <!--  </div>-->
  <router-view/>

</template>
<script>

export default {
  name: 'app',
  components: {}
}
</script>
<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  background: no-repeat center/contain url("/img/motif_graphique_animhead.png");
}


#app p.light {
  color: #FF064E;
}

#nav {
  padding: 30px;
  text-align: right;
}

#nav a {
  font-weight: bold;
  color: #000043;
  transition: .3s;
  margin: 0 10px;
}

#nav a.router-link-exact-active, #nav a:hover {
  color: #FF064E;
}
@media (min-width:980px ) and (max-width: 1200px) {
  body{
    background: no-repeat center/cover url("/img/motif_graphique_animhead.png");

  }
}

@media (max-width: 980px) {
  #nav a {
    font-size: 0.8em;
  }
  body{
    background: none;
    overflow: hidden;
    height: 100%;
  }
  body:after{
    content:"";
    position: absolute;
    display: block;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    background: no-repeat center/contain url("/img/motif_graphique_animhead.png");
    transform: rotate(90deg);
  }
}
</style>
