<template>
  <div class="about">
    <h1>Bonjour et bienvenue</h1>

    <div class="description">

      <p>Nous concevons un site d'informations et de vulgarisation de l'actualité de nos
        ministères, pour ce faire nous recherchons encore des personnes volontaires pour participer à la rédaction et à
        l'animation
        du site.</p>

      <p>Nous recherchons aussi des professeurs qui souhaiteraient participer à ce projet avec leurs élèves ayant des
        cours d'ECM,
        nous aimerions qu'ils prennent part au projet avec un exercice de quiz et de mini leçons.
      </p>

      <p>Notre projet contient des textes de loi, des jurisprudences et autres textes qui demandent certaines
        compétences ainsi nous recherchons aussi des
        étudiants en droit, des avocats et des juristes.</p>
    </div>
    <div class="link">
      <p>Si l'un de ces groupes vous concerne et que vous êtes intéressé et/ou souhaitez en savoir plus:</p>
      <form action="" @submit="validate" v-if="!isSend">
        <div class="form-group">
          <input class="form-field" placeholder="Votre nom" v-model="lastname" type="text" required>
          <input class="form-field" placeholder="Votre prénom" v-model="name" type="text" required>
          <input class="form-field" placeholder="Votre email" v-model="email" type="mail" required>
          <select class="form-field" v-model="categorie" required :class="{'select':categorie!=''}">
            <option value="" class="first-option">Choisir un groupe</option>
            <option value="1">Volontaire rédacteur</option>
            <option value="2">Enseignant.e</option>
            <option value="3">Étudiant.e en droit</option>
            <option value="4">Avocat.e</option>
            <option value="5">Juriste</option>
          </select>
          <select class="form-field interested-choice" v-model="interested_choice" required
                  :class="{'select':interested_choice!=''}">
            <option value="" class="first-option">Pourquoi nous contacter ?</option>
            <option value="1">Participer</option>
            <option value="2">Recevoir une brochure</option>
            <option value="3">Un entretien</option>
          </select>
          <button>Je suis interessé.e</button>

        </div>
      </form>
      <div class="is-send" v-else>
        <h3>Merci <b>{{ name }} {{ lastname }}</b> de votre intérêt pour notre projet nous vous recontactons très
          prochainement</h3>
      </div>
    </div>

  </div>
</template>
<script>
import emailjs from 'emailjs-com'


export default {
  name: 'About',
  data: () => {
    return {
      email: '',
      name: '',
      lastname: '',
      categorie: '',
      interested_choice: '',
      isSend: false

    }
  },
  methods: {
    async sendMail() {
      let cat = ''
      switch (this.categorie) {
        case "1":
          cat = "Volontaire rédacteur";
          break
        case "2":
          cat = "Enseignant.e";
          break
        case "3":
          cat = "Étudiant.e";
          break
        case "4":
          cat = "Avocat.e";
          break
        case "5":
          cat = "Juriste";
          break
      }

      let choice = ''
      switch (this.interested_choice) {
        case "1" :
          choice = "Participer";
          break;
        case "2" :
          choice = "Recevoir une brochure";
          break;
        case "3" :
          choice = "Un entretien";
          break;
      }

      let params = {
        'from_name': "MaDémocratie",
        'subject': "Prise d'information",
        'message': "Cette personne vous contact",
        'name': this.lastname.toUpperCase() + " " + this.name,
        'categorie': cat,
        'interested_choice':choice,
        'reply_to': this.email
      }


      let response = await emailjs.send('service_e9bwzyr', 'template_xxli45e', params, 'user_q7CrSbtpjORdWvoSTbHUn').then(function (resp) {
        return {succes: true}
      }, function (err) {
        return {error: err.text}
      })
      if (response.succes)
        this.isSend = response.succes
      else
        this.errorMessage = response.error
    },
    validate(e) {

      if (this.email && this.name && this.lastname && this.categorie && this.interested_choice)
        this.sendMail()


      e.preventDefault()
    }
  }
}
</script>
<style scoped>
.about{
  min-height: calc(100vh - 140px);
}
.description {
  margin: auto;
  width: 70%;
  text-align: left;
  padding: 20px;
}

.link {
  padding: 20px;
}

form input, form select.select {
  color: #FF064E;
}

form input, form select {
  padding: 10px;
  border: 1px solid #000043;
  border-radius: 5px;
  /*grid-column: 3;*/
  /*grid-row: 1;*/
}

form button {
  border: 1px solid #000043;
  background: #000043;
  border-radius: 5px;
  color: #fff;
  grid-column: 2;
  grid-row: 4;
  font-weight: bold;
  transition: .3s;
}

form button:hover {
  border: 1px solid #FF064E;
  background: #FF064E;
  cursor: pointer;
}

form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(50px, auto);
}
h3  b{
  color:#FF064E;
}
.form-group {
  grid-column: 2;
  grid-row: 1;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(50px, auto);
}

.form-field select option.first-option {
  color: grey !important;
}

@media (max-width: 650px){
  .form-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .form-field{
    flex: 1;
  }
  form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(50px, auto);
  }
  form button {
    margin-top:20px;
    padding:10px 20px;
  }
}
</style>


